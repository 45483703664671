import React from "react";
import theme from "theme";
import { Theme, Link, Strong, Span, Text, Button, Box, Structure, Image, Section, LinkBox, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaInstagram } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				DevTalks - 05 de Nov de 2022
			</title>
			<meta name={"description"} content={"Faça parte do primeiro workshop gratuito de tecnologia e inovação da A.E Developers. Um dia com muito aprendizado para todos os níveis, conexões com profissionais referência e direcionamentos de como usar a tecnologia para expansão do Reino."} />
			<meta property={"og:title"} content={"DevTalks - 05 de Nov de 2022"} />
			<meta property={"og:description"} content={"Faça parte do primeiro workshop gratuito de tecnologia e inovação da A.E Developers. Um dia com muito aprendizado para todos os níveis, conexões com profissionais referência e direcionamentos de como usar a tecnologia para expansão do Reino."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6344e5b449c9db002349cac5/images/android-icon-192x192.png?v=2022-10-17T22:05:43.699Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6344e5b449c9db002349cac5/images/zion-favicon.png?v=2022-10-16T01:44:51.956Z"} type={"image/x-icon"} />
		</Helmet>
		<Structure
			cells-number-total="2"
			justify-content="normal"
			cells-number-group="0"
			display="block"
			padding="80px 0px 80px 0px"
			background="linear-gradient(0deg,#161B28 26.2%,rgba(22, 27, 40, 0.81) 100%),#1e1e2a url(https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&h=2000) 0% 0% /cover no-repeat fixed border-box"
			sm-padding="0px 0px 0px 0px"
			sm-margin="-20px 0px 0px 0px"
		>
			<Override
				slot="Content"
				text-align="center"
				align-content="center"
				margin="10vh auto 80px auto"
				sm-display="block"
				sm-margin="4vh auto 80px auto"
				md-margin="10vh auto 80px auto"
				md-display="block"
				md-overflow-y="visible"
				display="flex"
				justify-content="space-between"
				flex-wrap="no-wrap"
				align-items="flex-start"
			>
				<Override slot="cell-0">
					<Box
						min-width="auto"
						min-height="100px"
						max-width="100%"
						sm-text-align="center"
						md-display="block"
						md-text-align="center"
						width="auto"
						margin="0px 0 0px 0px"
						display="grid"
					>
						<Components.QuarklycommunityKitLoopText
							color="--greenLight"
							border-color="#3affab"
							slides="Design,Segurança,Programação,Inovação"
							padding="0 0px 0px 0px"
							sm-display="inline-block"
							display="block"
							font="900 38px/50.4px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
							sm-font="900 44px/38.4px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
							md-text-align="center"
							md-font="900 34px/50.4px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
						>
							<Override
								slot="Looped Text"
								border-color="--color-greenLight"
								color="--greenLight"
								display="inline-block"
								sm-display="block"
								sm-margin="0 0px 0px 0px"
								margin="0 0px 0 0px"
							/>
							<Override slot="Before Text" color="#ffffff">
								Aprenda
							</Override>
							<Override slot="After Text" color="#ffffff">
								no DevTalks
							</Override>
						</Components.QuarklycommunityKitLoopText>
						<Text
							margin="30px 0px 0px 0px"
							color="#ffffff"
							font="18px/150% --fontFamily-googleUbuntu"
							sm-font="17px/150% --fontFamily-googleUbuntu"
							max-width="600px"
							sm-min-width="100%"
							sm-max-width="100%"
							sm-padding="0px 20px 0px 20px"
							md-font="14px/150% --fontFamily-googleUbuntu"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Se inscreva no nosso 1° workshop gratuito de tecnologia e inovação.
								</Strong>
								<br />
								<br />
								Um dia repleto de aprendizados para todos os níveis técnicos, conexões com profissionais referência do mercado de especialidades distintas e também exemplos práticos de como usar a tecnologia para expandir o Reino de Deus.
							</Span>
						</Text>
						<Button
							color="--primary"
							background="--color-greenLight"
							font="normal 600 20px/1.5 --fontFamily-googleUbuntu"
							margin="40px 0px 0px 0px"
							border-radius="50px"
							padding="24px 32px 24px 32px"
							text-transform="uppercase"
							min-width="min-content"
							max-width="fit-content"
							href="https://forms.gle/MsDykNTSczTSVtLw7"
							type="link"
							target="_blank"
							sm-text-decoration-line="initial"
							lg-text-decoration-line="initial"
						>
							quero participar
						</Button>
					</Box>
				</Override>
				<Override slot="cell-1">
					<Box
						min-width="100px"
						min-height="100px"
						text-align="center"
						sm-padding="50px 0px 0px 0px"
						display="grid"
					>
						<Box
							display="inline-block"
							padding="10px 30px 10px 30px"
							color="--light"
							background="--color-greyD2"
							text-align="center"
							margin="0px 0px 30px 0px"
							position="static"
						>
							<Text
								font="80px --fontFamily-googleBebasNeue"
								color="--light"
								text-align="center"
								padding="0px 0px 0 0px"
								margin="0 0px 0 0px"
							>
								05
							</Text>
							<Text font="30px --fontFamily-googleBebasNeue" color="--light" text-align="center" margin="0 0px 0 0px">
								NOV/22
							</Text>
						</Box>
						<Box
							display="block"
							border-width="4px"
							border-color="#ffffff"
							border-style="solid"
							margin="0px 0px 0px 0px"
							text-align="center"
							padding="10px 10px 10px 10px"
							width="100%"
						>
							<Text margin="0px 0px 0px 0px" color="#ffffff" font="25px --fontFamily-googleUbuntu">
								Das{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									09:00hs
								</Strong>
								<br />
								às{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									14:00hs
								</Strong>
							</Text>
						</Box>
					</Box>
				</Override>
				<Override
					slot="Cell 0th"
					justify-self="end"
					align-self="center"
					position="static"
					display="block"
					text-align="left"
					md-height="318px"
					md-display="inline-block"
					md-margin="0px 0px 0px 0px"
					sm-display="inline-block"
					min-width="250px"
					padding="0px 32px 0px 0"
					margin="0px 32px 0px 0px"
					order="-1"
					sm-padding="0px 0 0px 0"
					max-width="800px"
					md-padding="0px 0 0px 0"
					sm-margin="40px 0px 0px 0px"
				/>
				<Override slot="Cell" display="flex" min-width="600px" />
			</Override>
		</Structure>
		<Section padding="40px 0 40px 0" sm-padding="0px 0 0px 0" lg-padding="0 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="none"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-flex="0 0 auto"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://images.unsplash.com/photo-1603975711481-18b7aaca4caa?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&h=2000"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
					/>
					<Image
						src="https://images.unsplash.com/photo-1522252234503-e356532cafd5?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
					/>
					<Image
						src="https://images.unsplash.com/photo-1504812445817-cb3d4f235a14?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&h=2000"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
					/>
					<Image
						src="https://images.unsplash.com/photo-1520642413789-2bd6770d59e3?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&h=2000"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Override slot="Content" display="grid" justify-items="center">
					<Override slot="cell-0">
						<Text
							border-color="--color-green"
							color="--primary"
							font="normal 900 20px/1.2 --fontFamily-googleUbuntu"
							text-transform="uppercase"
							display="inline-block"
							background="--color-greenLight"
							padding="10px 20px 10px 20px"
						>
							Vamos falar sobre:
						</Text>
						<Components.EventPresenter
							presenterTitle="Victor Caetano"
							presenterInstitution="Engineering Manager na XP Inc."
							presenterSubject="Tecnologia aplicada ao Reino de Deus"
							presenterName="Victor Caetano"
							margin="0 0 0 0"
						/>
						<Components.EventPresenter
							presenterTitle="Max Cruz Franco"
							presenterInstitution="Tech Lead na Dasa"
							presenterSubject="Carreira Fullstack"
							presenterName="Francisco Vilanova"
							margin="0 0 0 0"
						/>
						<Components.EventPresenter
							presenterTitle="Max Cruz Franco"
							presenterInstitution="Product Design Lead na XP Inc."
							presenterSubject="User Experience em produtos digitais"
							presenterName="Vinícius Martins"
							margin="0 0 0 0"
						/>
						<Components.EventPresenter
							presenterTitle="Max Cruz Franco"
							presenterInstitution="Software Engineer na Hyperativa"
							presenterSubject="Segurança desde o princípio"
							presenterName="Max Franco"
							margin="0 0 0 0"
						/>
					</Override>
					<Override slot="Cell 0th" text-align="left" />
				</Override>
				<Structure
					background="#161B28"
					cells-number-total="1"
					width="auto"
					display="block"
					padding="0px 0px 100px 0px"
				>
					<Override slot="Content" display="grid" justify-items="center">
						<Override slot="cell-0">
							<Text
								border-color="--color-green"
								color="--primary"
								font="normal 900 20px/1.2 --fontFamily-googleUbuntu"
								text-transform="uppercase"
								display="inline-block"
								background="--color-greenLight"
								padding="10px 20px 10px 20px"
							>
								Vamos falar sobre:
							</Text>
							<Components.EventPresenter
								presenterTitle="Victor Caetano"
								presenterInstitution="Engineering Manager na XP Inc."
								presenterSubject="Como usar a Tecnologia para a expansão do Reino"
								presenterName="Victor Caetano"
								margin="0 0 0 0"
							/>
							<Components.EventPresenter
								presenterTitle="Max Cruz Franco"
								presenterInstitution="Tech Lead na Dasa"
								presenterSubject="Carreira Fullstack"
								presenterName="Francisco Vilanova"
								margin="0 0 0 0"
							/>
							<Components.EventPresenter
								presenterTitle="Max Cruz Franco"
								presenterInstitution="Product Design Lead na XP Inc."
								presenterSubject="User Experience em produtos digitais"
								presenterName="Vinícius Martins"
								margin="0 0 0 0"
							/>
							<Components.EventPresenter
								presenterTitle="Max Cruz Franco"
								presenterInstitution="Software Engineer na Hyperativa"
								presenterSubject="Segurança desde o princípio"
								presenterName="Max Franco"
								margin="0 0 0 0"
							/>
						</Override>
						<Override slot="Cell 0th" text-align="left" />
					</Override>
				</Structure>
			</Box>
		</Section>
		<Structure cells-number-total="2" md-display="flex">
			<Override slot="Content" md-display="block" lg-display="block">
				<Override slot="cell-1">
					<Box min-width="100px" min-height="100px" padding="0px 0px 0px 50px" sm-padding="0px 0px 0px 0">
						<Text
							border-color="--color-dark"
							color="--primary"
							font="normal 900 20px/1.2 --fontFamily-googleUbuntu"
							text-transform="uppercase"
							display="inline-block"
							padding="10px 20px 10px 20px"
							background="--color-greenLight"
						>
							local
						</Text>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="16px/150% --fontFamily-googleUbuntu">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Zion Church - Campus Morumbi
									<br />
								</Strong>
								<br />
								Rua Liberato Carvalho Leite, 86 - Vila Sônia,
								<br />
							</Span>
							São Paulo - SP, 05630-100
						</Text>
						<Link
							href="https://goo.gl/maps/DYRP7qGYZehTDhcw8"
							color="--greenLight"
							margin="30px 0px 0px 0px"
							display="inline-block"
							target="_blank"
							text-decoration-line="initial"
							hover-color="#d1ffeb"
						>
							<Strong>
								Ver no mapa
							</Strong>
						</Link>
					</Box>
				</Override>
				<Override slot="cell-0">
					<LinkBox href="https://goo.gl/maps/DYRP7qGYZehTDhcw8" target="_blank">
						<Image
							src="https://uploads.quarkly.io/6344e5b449c9db002349cac5/images/Screenshot%20from%202022-10-12%2015-09-33.png?v=2022-10-12T18:11:09.879Z"
							display="block"
							max-width="700px"
							border-width="6px"
							border-style="solid"
							border-color="--color-dark"
							sm-width="100%"
							border-radius="20px"
							lg-width="auto"
						/>
					</LinkBox>
				</Override>
				<Override slot="Cell 0th" border-width={0} md-display="grid" border-radius="50px" />
			</Override>
		</Structure>
		<Structure cells-number-total="2">
			<Override slot="Content" sm-display="inline-block" sm-padding="0px 0px 32px 0px">
				<Override slot="cell-0">
					<Link href="https://zionchurch.org.br/" target="_blank">
						<Image src="https://uploads.quarkly.io/6344e5b449c9db002349cac5/images/zion_logo1675_500.png?v=2022-10-12T15:38:50.558Z" display="block" max-width="180px" sm-display="inline-block" />
					</Link>
				</Override>
				<Override slot="cell-1">
					<Box text-align="right" margin="0px 0px 20px 0px" sm-text-align="center">
						<Link href="https://www.instagram.com/zionsaopaulo/" target="_blank">
							<Icon
								category="fa"
								icon={FaInstagram}
								size="24px"
								color="#ffffff"
								display="inline-block"
							/>
						</Link>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--grey"
						font="12px/200% --fontFamily-googleUbuntu"
						text-align="right"
						sm-text-align="center"
					>
						As inscrições encerram no dia 04 de novembro de 2022.
						<br />
						Este evento é gratuito e promovido pela Área Executiva Developers.{" "}
						<br />
						2022 Copyright © - Zion Church. Todos os direitos reservados
					</Text>
				</Override>
				<Override slot="cell-2" />
				<Override
					slot="Cell 0th"
					text-align="center"
					lg-order="0"
					lg-text-align="center"
					sm-text-align="center"
					sm-padding="50px 0px 0px 0px"
				/>
			</Override>
		</Structure>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6344e5b449c9db002349cac3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-HKSKJ0Y60Z"} place={"endOfHead"} rawKey={"634dd5815ba59e500ec4c573"} />
			<script async={false} place={"endOfHead"} rawKey={"634dd5b5b9e70e336f4fe481"}>
				{"window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-HKSKJ0Y60Z');"}
			</script>
		</RawHtml>
	</Theme>;
});