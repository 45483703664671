import React from 'react';
import atomize from "@quarkly/atomize";

const EventPresenter = props => <div {...props}>
	<div style={{
		position: "relative",
		padding: "40px 0",
		fontFamily: "Ubuntu"
	}}>
		<h2 style={{
			position: "relative",
			margin: 0,
			padding: "0 0 0 30px",
			textTransform: "uppercase",
			color: "#fff"
		}}>
			<div style={{
				border: "4px solid #fff",
				position: "absolute",
				padding: "7px",
				borderRadius: "100%",
				background: "#34f0a1",
				left: "-9px",
				top: "15px",
				zIndex: 1
			}}></div>
			{props.presenterSubject}
		</h2>
		<p style={{
			margin: "10px 0 0 20px",
			padding: "0 0 0 20px",
			color: "#00b4af"
		}}>
			{props.presenterName}
			 / 
			{props.presenterInstitution}
		</p>
		<div style={{
			position: "absolute",
			width: "4px",
			background: "rgba(255,255,255,.3)",
			top: 0,
			bottom: 0,
			left: "0px",
			zIndex: 0
		}}></div>
	</div>
</div>;

export default atomize(EventPresenter)({
	name: "EventPresenter",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "EventPresenter — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		presenterName: {
			control: "input"
		},
		presenterSubject: {
			control: "input"
		},
		presenterInstitution: {
			control: "input"
		}
	}
});